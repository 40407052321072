import { ILexiconEntityAttributes } from 'Models/Entities';
import { IModelAttributes } from 'Models/Model';

export const defaultLexicon: Omit<
    ILexiconEntityAttributes, 'institutionId' | 'institution' | keyof IModelAttributes
> = {
	academicAdvisor: 'Academic Advisor',
	administrator: 'Administrator',
	classDisplayName: 'Class',
	code: 'Code',
	commencingYear: 'Commencing year',
	component: 'Component',
	components: 'Components',
	termsAndConditions: 'Terms and Conditions',
	completedCredit: 'Completed/Credit',
	course: 'Course',
	courses: 'Courses',
	curriculumSupport: 'Curriculum Support',
	discipline: 'Discipline',
	duplicate: 'Duplicate',
	duplicates: 'Duplicates',
	duplicateSelectionSummary: 'Duplicate Selection Summary',
	duplicateWarningBannerText: 'This course has been selected in more than one component in your program.',
	enrol: 'Enrol',
	faculty: 'Faculty',
	institutionAdmin: 'Institution Admin',
	location: 'Location',
	program: 'Program',
	requirementsFulfilmentCheck: 'Requirements Fulfilment Check',
	requisites: 'Requisites',
	requisiteWarning: 'Requisite condition not met',
	rules: 'Rules',
	school: 'School',
	selectionNotice: 'Selection notice',
	selectionNoticeText: 'Selection does not meet requirements',
	studentID: 'Student ID',
	studyPeriod: 'Study period',
	studyPlan: 'Study plan',
	studyPlans: 'Study plans',
	structure: 'Structure',
	structures: 'Structures',
	term: 'Term',
	title: 'Title',
	units: 'Units',
	unitsShort: 'Units',
};

export default defaultLexicon;
